import "tailwindcss/tailwind.css";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { useInitializing, useThunkDispatch } from "../common/hooks";
import { useEffect } from "react";
import axios from "axios";
import { csrfHeader } from "../common/gateway";
import { AUTHENTICATED, CSRF_TOKEN } from "../redux/types";
import { initialize } from "../redux/actions";
import { Provider } from "react-redux";
import { useStore } from "../redux/store";
import Loading from "../components/pages/public/Loading";
import { useRouter } from "next/router";
import { isProduction } from "../common/utils";

const LoadApp = (props: {
  skipInitializing?: boolean;
  children: JSX.Element;
}) => {
  const { skipInitializing, children } = props;

  const initializing = useInitializing();
  const dispatch = useThunkDispatch();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        if (csrfHeader.toLowerCase() in response.headers) {
          dispatch({
            type: CSRF_TOKEN,
            payload: response.headers[csrfHeader.toLowerCase()],
          });
        }

        return response;
      },
      (error) => {
        if (!!error.response && error.response.status === 401) {
          // TODO: display some sort of error message.

          dispatch({
            type: AUTHENTICATED,
            paylaod: false,
          });
        }

        return Promise.reject(error);
      }
    );

    if (!skipInitializing) {
      dispatch(initialize());
    }
  }, [skipInitializing]);

  return !skipInitializing && initializing ? <Loading /> : children;
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  const store = useStore(pageProps.initialReduxState);
  const router = useRouter();

  const handleRouteChange = (url: URL) => {
    (window as any).gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  };

  useEffect(() => {
    if (isProduction()) {
      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [router.events]);

  return (
    <Provider store={store}>
      <LoadApp skipInitializing={!router.asPath.includes("/manage")}>
        <Component {...pageProps} />
      </LoadApp>
    </Provider>
  );
};

export default MyApp;
