import { isServer } from "../common/utils";

export const get = (key: string) => {
  if (isServer()) {
    return undefined;
  }

  const raw = localStorage.getItem(key);
  if (!!raw) {
    return raw;
  } else {
    return undefined;
  }
};

export const set = (key: string, value: string) => {
  if (isServer()) {
    return;
  }

  localStorage.setItem(key, value);
};

export const remove = (key: string) => {
  if (isServer()) {
    return;
  }

  localStorage.removeItem(key);
};
