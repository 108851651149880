import { AnyAction, combineReducers } from "redux";
import { remove, set } from "../client/storage";
import { AUTH_TOKEN } from "../common/constants";
import IUser from "../common/models/user";
import { IOverlayModalState, IOverlayNotifState } from "./state";
import { AUTHENTICATED, CSRF_TOKEN, INITIALIZING, OVERLAY_MODAL, OVERLAY_NOTIF, USER_LOADED } from "./types";

export const authenticated = (state: boolean | null = false, action: AnyAction) => {
  switch (action.type) {
    case AUTHENTICATED:
      if (!!action.payload) {
        set(AUTH_TOKEN, "1");
        return true;
      } else {
        remove(AUTH_TOKEN);
        return false;
      }
    default:
      return state;
  }
};

const initializing = (state: boolean | null = false, action: AnyAction) => {
  switch (action.type) {
    case INITIALIZING:
      return action.payload;
    default:
      return state;
  }
};

const csrfToken = (state: string | null = null, action: AnyAction) => {
  switch (action.type) {
    case CSRF_TOKEN:
      return action.payload;
    default:
      return state;
  }
};

const user = (state: IUser | null = null, action: AnyAction) => {
  switch (action.type) {
    case AUTHENTICATED:
      if (!action.payload) {
        return null;
      }

      return state;
    case USER_LOADED:
      return action.payload;
    default:
      return state;
  }
};

const overlayNotif = (state: IOverlayNotifState | null = null, action: AnyAction) => {
  switch (action.type) {
    case OVERLAY_NOTIF:
      return !!action.payload ? {
        show: true,
        body: action.payload,
      } : {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

const overlayModal = (state: IOverlayModalState | null = null, action: AnyAction) => {
  switch (action.type) {
    case OVERLAY_MODAL:
      return !!action.payload ? {
        show: true,
        body: action.payload,
      } : {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  authenticated,
  initializing,
  csrfToken,
  user,
  overlayNotif,
  overlayModal,
});