import { useMemo } from 'react';
import { createStore, applyMiddleware, Store } from 'redux';
import { get } from '../client/storage';
import { AUTH_TOKEN } from '../common/constants';
import reducer from './reducers';
import { IState } from './state';
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { isProduction } from '../common/utils';

var store: Store;

const authenticated = get(AUTH_TOKEN);

const initialState: IState = {
  authenticated: !!authenticated,
  initializing: true,
};

const middlewares = isProduction() ? [thunk] : [thunk, logger]; 

const initializeStoreInternal = (preloadedState: IState = initialState) => {
  return createStore(
    reducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )
};

export const initializeStore = (preloadedState: IState) => {
  let _store = store ?? initializeStoreInternal(preloadedState);

  if (preloadedState && store) {
    _store = initializeStoreInternal({
      ...store.getState(),
      ...preloadedState,
    });

    store = undefined;
  }

  if (typeof window === 'undefined') return _store;
  if (!store) store = _store;
  return _store;
};

export const useStore = (initialState: IState) => {
  return useMemo(() => initializeStore(initialState), [initialState])
};