import PageHead from "../../elements/PageHead";

const Loading = () => (
  <>
    <PageHead />
    <div className="flex items-center justify-center h-screen">
      <div className="pure-loader">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </>
);

export default Loading;
